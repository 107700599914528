// Here you can add other styles
.form-control.is-invalid {
	background-image: none; // remove cross image
}

.cover {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
}

.visually-hidden {
	opacity: 0;
	width: 0;
	height: 0;
	overflow: hidden;
	padding: 0;
	margin: 0;
	border: 0;
	outline: none;
	position: absolute;
}

.number-input {
	padding-left: 50px;
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		/* display: none; <- Crashes Chrome on hover */
		-webkit-appearance: none;
		margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
	}

	&[type="number"] {
		-moz-appearance: textfield; /* Firefox */
	}
}

.tooltip-inner {
	max-width: 350px;
	/* If max-width does not work, try using width instead */
	width: 350px;
	font-size: 14px;
	div {
		text-align: left;
		margin-bottom: 5px;
		padding-left: 15px;
	}
}

.update-icon {
	cursor: pointer;
	font-size: large;
	margin-left: 20px;
}

.custom-icon {
	cursor: pointer;
	font-size: large;
	margin-left: 10px;
	margin-right : 10px;
}

.info-pill {
	background: #111;
	padding: 5px 10px;
	color: #fff;
	border-radius: 5px;
}

.error-feedback {
	display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #f86c6b;
}

.search_icon {
    position: absolute;
    right: 40px;
    top: 20px;
}