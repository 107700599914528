// Variable overrides

$theme: #eee;
$primary: #47aa9c;
$secondary: #3d58c3;
$warning : #753425;
$white: #fff;
$info: #3d58c3;
$link: #19f9b9;

$linkColor: #753425;
$borderGrey: #e0e0e0;
$bgGrey: #f6f6f6;
$darkGray: #24272c;
$bgDark: #01091e;
$darkBlue: #011851;
